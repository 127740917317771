// Generated by Framer (6d82f59)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-KiCOe"

const variantClassNames = {pydKsrMnq: "framer-v-11r4bj3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const numberToPixelString = (value) => {
    if (typeof value !== "number") return value;
    if (!Number.isFinite(value)) return undefined;
    return Math.max(0, value) + "px";
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({background, border, color, dotColor, dotVisible, height, id, padding, title, width, ...props}) => { return {...props, fLSQhPFEc: background ?? props.fLSQhPFEc ?? "var(--token-82e6e4db-c5aa-43e1-99e0-e9f12d384e98, rgb(250, 250, 250))", h043NUh1y: dotColor ?? props.h043NUh1y ?? "var(--token-4b6aa058-5667-44dc-b16d-1d99d2bc2e4e, rgb(113, 113, 122))", hmlzwtOlI: title ?? props.hmlzwtOlI ?? "Badge", P_8aWiLQX: dotVisible ?? props.P_8aWiLQX ?? true, q3nDAwx5I: border ?? props.q3nDAwx5I ?? {borderColor: "rgba(107, 114, 128, 0.10000000149011612)", borderStyle: "solid", borderWidth: 1}, rsdiP6KDB: padding ?? props.rsdiP6KDB ?? "4px 10px 4px 10px", xqmYrrtUj: color ?? props.xqmYrrtUj ?? "var(--token-b5b3ef5a-d056-45ad-9307-b9c59484c5e5, rgb(24, 24, 27))"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;color?: string;background?: string;border?: Record<string, any>;padding?: string;dotVisible?: boolean;dotColor?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, hmlzwtOlI, xqmYrrtUj, fLSQhPFEc, q3nDAwx5I, rsdiP6KDB, P_8aWiLQX, h043NUh1y, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "pydKsrMnq", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-11r4bj3", className, classNames)} data-border data-framer-name={"Badge + Dot"} layoutDependency={layoutDependency} layoutId={"pydKsrMnq"} ref={ref ?? ref1} style={{"--1yvn6o7": numberToPixelString(rsdiP6KDB), "--border-bottom-width": (q3nDAwx5I.borderBottomWidth ?? q3nDAwx5I.borderWidth) + "px", "--border-color": q3nDAwx5I.borderColor, "--border-left-width": (q3nDAwx5I.borderLeftWidth ?? q3nDAwx5I.borderWidth) + "px", "--border-right-width": (q3nDAwx5I.borderRightWidth ?? q3nDAwx5I.borderWidth) + "px", "--border-style": q3nDAwx5I.borderStyle, "--border-top-width": (q3nDAwx5I.borderTopWidth ?? q3nDAwx5I.borderWidth) + "px", backgroundColor: fLSQhPFEc, borderBottomLeftRadius: 9999, borderBottomRightRadius: 9999, borderTopLeftRadius: 9999, borderTopRightRadius: 9999, ...style}}>{P_8aWiLQX && (<motion.div className={"framer-1fyv9i9"} data-framer-name={"Dot"} layoutDependency={layoutDependency} layoutId={"arjI09gMe"} style={{backgroundColor: h043NUh1y, borderBottomLeftRadius: 9999, borderBottomRightRadius: 9999, borderTopLeftRadius: 9999, borderTopRightRadius: 9999}}/>)}<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "12px", "--framer-letter-spacing": "0px", "--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-xqmYrrtUj-fj3naIO9X))"}}>Badge</motion.p></React.Fragment>} className={"framer-10mfgd6"} data-framer-name={"Badge"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"WWZsXsZrF"} style={{"--extracted-r6o4lv": "var(--variable-reference-xqmYrrtUj-fj3naIO9X)", "--framer-paragraph-spacing": "0px", "--variable-reference-xqmYrrtUj-fj3naIO9X": xqmYrrtUj}} text={hmlzwtOlI} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KiCOe.framer-tap5qr, .framer-KiCOe .framer-tap5qr { display: block; }", ".framer-KiCOe.framer-11r4bj3 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: hidden; padding: var(--1yvn6o7); position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-KiCOe .framer-1fyv9i9 { flex: none; height: 8px; overflow: hidden; position: relative; width: 8px; will-change: var(--framer-will-change-override, transform); }", ".framer-KiCOe .framer-10mfgd6 { flex: none; height: auto; position: relative; white-space: pre; width: auto; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-KiCOe.framer-11r4bj3 { gap: 0px; } .framer-KiCOe.framer-11r4bj3 > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-KiCOe.framer-11r4bj3 > :first-child { margin-left: 0px; } .framer-KiCOe.framer-11r4bj3 > :last-child { margin-right: 0px; } }", ".framer-KiCOe[data-border=\"true\"]::after, .framer-KiCOe [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 72
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"hmlzwtOlI":"title","xqmYrrtUj":"color","fLSQhPFEc":"background","q3nDAwx5I":"border","rsdiP6KDB":"padding","P_8aWiLQX":"dotVisible","h043NUh1y":"dotColor"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerfj3naIO9X: React.ComponentType<Props> = withCSS(Component, css, "framer-KiCOe") as typeof Component;
export default Framerfj3naIO9X;

Framerfj3naIO9X.displayName = "Badge + Dot";

Framerfj3naIO9X.defaultProps = {height: 22, width: 72};

addPropertyControls(Framerfj3naIO9X, {hmlzwtOlI: {defaultValue: "Badge", displayTextArea: false, title: "Title", type: ControlType.String}, xqmYrrtUj: {defaultValue: "var(--token-b5b3ef5a-d056-45ad-9307-b9c59484c5e5, rgb(24, 24, 27)) /* {\"name\":\"gray/900\"} */", title: "Color", type: ControlType.Color}, fLSQhPFEc: {defaultValue: "var(--token-82e6e4db-c5aa-43e1-99e0-e9f12d384e98, rgb(250, 250, 250)) /* {\"name\":\"gray/50\"} */", title: "Background", type: ControlType.Color}, q3nDAwx5I: {defaultValue: {borderColor: "rgba(107, 114, 128, 0.10000000149011612)", borderStyle: "solid", borderWidth: 1}, title: "Border", type: ControlType.Border}, rsdiP6KDB: {defaultValue: "4px 10px 4px 10px", title: "Padding", type: ControlType.Padding}, P_8aWiLQX: {defaultValue: true, title: "Dot Visible", type: ControlType.Boolean}, h043NUh1y: {defaultValue: "var(--token-4b6aa058-5667-44dc-b16d-1d99d2bc2e4e, rgb(113, 113, 122)) /* {\"name\":\"gray/500\"} */", title: "Dot Color", type: ControlType.Color}} as any)

addFonts(Framerfj3naIO9X, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})